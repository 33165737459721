import { XIcon } from "@heroicons/react/outline";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import SignaturePad from "signature_pad";
import { useWindowProperties } from "../customHooks";
import { useTranslation } from "react-i18next";

export interface SignatreFieldRef {
  getSignatureData: () => string;
}

interface Props {
  className?: string;
  text?: string;
  renderText?: boolean;
  setHasChanges: (f: boolean) => void;
}

function SignatureField(props: Props, forwaredRef: React.ForwardedRef<SignatreFieldRef>) {
  const { text, renderText, setHasChanges } = props;

  const { t } = useTranslation();
  const canvas = useRef<HTMLCanvasElement>(null);
  const signaturePad = useRef<SignaturePad>()

  const [scale, setScale] = useState(1);
  const [showClear, setShowClear] = useState<boolean>(false);

  const windowProperties = useWindowProperties();
  const pixelRatio = windowProperties.pixelRatio;
  const isDesktop = windowProperties.width >= 1024;


  useImperativeHandle(forwaredRef, () => ({
    getSignatureData: () => canvas.current!.toDataURL(),
  }));

  useEffect(() => {
    if (!canvas.current)
      return;

    const el = canvas.current;
    signaturePad.current = new SignaturePad(el);
    signaturePad.current.clear();

    el.width = el.offsetWidth * pixelRatio;
    el.height = el.width / (isDesktop ? 4.22222 : 2.66);
    el.getContext("2d")?.scale(pixelRatio, pixelRatio);

    const scale = el.offsetWidth / 600;
    setScale(scale);

    const showClear = () => setShowClear(true);

    signaturePad.current.addEventListener("endStroke", showClear);
    return () => signaturePad.current?.removeEventListener("endStroke", showClear);

  }, [pixelRatio, windowProperties.width, scale, isDesktop]); // NOTE: Scale required as a dep to re-compute correct cavas size properties.

  useEffect(() => {
    clearField();

    if (!renderText || !text)
      return;

    const el = canvas.current!;

    const ctx = el.getContext("2d")! as CanvasRenderingContext2D;
    const fontSize = 72 * scale;

    ctx.strokeStyle = "black";
    ctx.font = `${fontSize}px Sacramento`;
    ctx.fillText(text, 48 * scale, (el.height / pixelRatio) - 42 * scale);
  }, [text, renderText, pixelRatio, scale]);

  useEffect(() => {
    setHasChanges(showClear);
  }, [showClear, setHasChanges]);

  function clearField() {
    setShowClear(false);
    signaturePad.current?.clear();
  }

  return (
    <div className={"relative bg-gray " + props.className}>
      <div className="absolute bg-black opacity-20" style={{ left: 20 * scale, bottom: 40 * scale, width: `calc(100% - ${40 * scale}px)`, height: "2px" }} />
      <XIcon className="absolute text-black opacity-20 w-4 h-4" style={{ left: 28 * scale, bottom: 48 * scale }} />
      {showClear &&
        <button className="absolute right-2 top-2 bg-white hover:bg-red text-gray hover:text-white shadow rounded-sm px-2 pt-1 text-xs cursor-pointer z-50" onClick={clearField}>
          {t("Clear")}
        </button>
      }
      <canvas ref={canvas} className={"relative w-full h-full " + (props.renderText ? "pointer-events-none" : "pointer-events-auto")} />
    </div>
  );
}

const _SignatureField = forwardRef<SignatreFieldRef, Props>(SignatureField);
export default _SignatureField;
