import { TranslateIcon } from "@heroicons/react/outline";
import Select, { SelectOption } from "./Select";
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const lang_map_en = { en: "English", es: "Spanish", fr: "French", de: "German" };

type Props = {
  className?: string;
}

const LanguagePicker = (props: Props) => {
  const { className = "" } = props;
  const { t } = useTranslation();
  const [language, setLanguage] = useState({ label: "English", value: "en" });

  const LANGUAGES = [{ value: "en", label: t("English") }, { value: "es", label: t("Spanish") }, { value: "fr", label: t("French") }, { value: "de", label: t("German") }];
  const handleLanguageChange = (newLanguage: SelectOption) => {
    changeLanguage(newLanguage.value)
    setLanguage({ value: newLanguage.value, label: t(lang_map_en[newLanguage.value as keyof typeof lang_map_en]) });
  }

  return (
    <div className={`text-sm flex gap-2 items-center ${className}`}>
      <TranslateIcon className="h-5 w-5 mr-1" />
      <Select selected={language} options={LANGUAGES} onChange={handleLanguageChange} />
    </div>
  );
}

export default LanguagePicker;
