import { useState } from "react";

export type SelectOption = { label: string, value: string };

type Props = {
  options: Array<SelectOption>;
  selected: { value: string, label: string };
  onChange: (newValue: SelectOption) => void;
}

const Select = (props: Props) => {
  const { options, selected = { value: "", label: "" }, onChange } = props
  const [open, setOpen] = useState(false);

  return (
    <div className="relative overflow-visible">
      <button className="border-b-2 flex justify-between items-baseline border-white w-24" onClick={() => setOpen(prev => !prev)}>
        {selected.label}
        <span className="mr-2 border-white border-solid border-t-0 border-l-0 border-2 inline-block p-1 rotate-45 -translate-y-1/2" />
      </button>
      {open && (
        <div className="absolute w-32 bottom-0 flex flex-col bg-white gap-2 rounded drop-shadow-md">
          {options.map(opt => (
            <button key={opt.value} onClick={(e) => { setOpen(false); onChange(opt) }} className="text-black p-4 bg-white hover:bg-gray transition-colors first:rounded-t last:rounded-b text-left">
              {opt.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default Select;