import React, { Dispatch } from "react";
import { Document, Page } from "react-pdf";
import { SignableDocument } from "../types";
import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";
import { useTranslation } from "react-i18next";

type Props = {
  signableDocument: SignableDocument;
  pageNumbers: number[];
  pageWidth: number;
  selectedPage: number;
  setSelectedPage: Dispatch<React.SetStateAction<number>>;
  onLoadSuccess?: (pdf?: PDFDocumentProxy) => void;
}

const PageThumbnails = (props: Props) => {
  const { pageWidth, signableDocument, pageNumbers, selectedPage, setSelectedPage, onLoadSuccess } = props;
  const { t } = useTranslation();

  const onPageClick = (pageNumber: number) => {
    setSelectedPage(pageNumber);
    const page = document.querySelector(`.main-page-${pageNumber}`) as HTMLElement;
    if (page) {
      page.scrollIntoView({ inline: "center", block: "center" });
    }
  }

  const handleSuccess = (page: PDFPageProxy) => {
    if (page.pageNumber === pageNumbers.length) {
      onLoadSuccess?.()
    };
  }

  return (
    <div className="fixed flex flex-col gap-4 w-36 items-center flex-shrink-0">
      <h3 className="font-semibold w-full px-3">{t("Pages")} ({pageNumbers.length})</h3>
      <Document file={signableDocument!.documentUrl} className="lg:overflow-y-auto flex-shrink-0 flex flex-col gap-4" loading={<div className="opacity-0" />}>
        <div className="flex flex-col gap-4 w-full h-[calc(100vh-192px)]">
          {pageNumbers.map((n: number) =>
            <div key={n} tabIndex={0} className={`relative flex flex-col items-center justify-center rounded p-2 cursor-pointer transition-all border border-blue border-opacity-0 ${selectedPage === n ? 'bg-[#1875FB29] border-opacity-100' : 'hover:bg-[#1877fb0b] hover:border-opacity-60'}`} onClick={() => onPageClick(n)}>
              <Page pageNumber={n} width={100} className="rounded shadow overflow-hidden" renderTextLayer={false} renderAnnotationLayer={false} loading={<div className="opacity-0" style={{ width: `${pageWidth}px` }} />} onLoadSuccess={handleSuccess} />
              <h4 className="text-xs font-semibold mt-1.5">{n}</h4>
            </div>
          )}
        </div>
      </Document>
    </div>

  );
}

export default PageThumbnails;