import { useContext, useEffect, useState } from "react";
import { FieldRegion } from "../types";
import { DocumentContext, ModalContext, GlobalStateContext } from "../contexts";
import { GlobalStateActionType } from "../reducers";
import { formatDate, formatTime } from "../formatting";
import { useTranslation } from "react-i18next";

function PageOverlay(props: { width: number, pageNumber: number }) {
  const { pageNumber } = props;
  const { t } = useTranslation();

  const { state, dispatch } = useContext(GlobalStateContext);
  const { signatureData, signatureDateString } = state;

  const { signatureRequest, signableDocument, pdfDocument } = useContext(DocumentContext);
  const { openModal } = useContext(ModalContext);

  const [scale, setScale] = useState<number>(1);

  function updateTextValue(fieldId: string, textValue: string) {
    dispatch({ type: GlobalStateActionType.UPDATE_FIELD_VALUE, fieldId, textValue });
  }

  useEffect(() => {
    try {
      pdfDocument?.getPage(pageNumber).then((page) => {
        const viewport = page.getViewport({ scale: 1 });
        setScale(props.width / viewport.width);
      });
    } catch (e) { console.error(e) }
  }, [pdfDocument, pageNumber, props.width]);

  const now = new Date();
  const timeString = formatTime(now, signatureRequest?.formatting?.dateTimeFormat ?? "12_HOUR");
  const dateString = (formatDate(now, signatureRequest?.formatting?.dateFormat ?? "MM/DD/YYYY") + " " + timeString).trim();
  const regions = signableDocument!.fieldRegions
    .filter(r => r.recipient === signatureRequest!.recipientNumber)
    .filter(r => r.pageNumber === pageNumber)
    .map((region: FieldRegion, i: number) => {
      const style = {
        left: region.bbox.x0 * scale - 1,
        top: region.bbox.y0 * scale - 1,
        width: (region.bbox.x1 - region.bbox.x0) * scale + 2 + (region.type === "CHECKBOX" ? 1 : 0),
        height: (region.bbox.y1 - region.bbox.y0) * scale + 2 + (region.type === "CHECKBOX" ? 1 : 0),
        fontSize: 12 * scale
      }

      if (region.type === "DATE") {

        if (signatureDateString)
          return <div key={i} className="absolute flex items-center whitespace-nowrap select-none" style={style}>{signatureDateString}</div>;
        else
          return <div key={i} className="absolute flex items-center whitespace-nowrap select-none text-blue" style={style}>{dateString}</div>;
      }

      if (region.type === "TEXT") {
        return (
          <div key={i} className="absolute font-sans text-" style={{ ...style, maxWidth: (600 * scale) - (style.left + 20), minWidth: style.width, width: "auto" }}>
            <div contentEditable key={i} className="px-2 h-full text-black bg-white border-blue border rounded overflow-hidden outline-none whitespace-nowrap" onInput={e => { updateTextValue(region.id, e.currentTarget.innerText) }} style={{ maxWidth: (600 * scale) - (style.left + 20), minWidth: style.width, width: "auto", paddingTop: (style.height - style.fontSize * 1.5) / 2 }} />
            {!state.fieldValues[region.id] &&
              <div className="text-gray absolute select-none pointer-events-none whitespace-nowrap h-full px-2 top-0 left-0 mt-px ml-px" style={{ paddingTop: (style.height - style.fontSize * 1.5) / 2 }}>Ab</div>
            }
          </div>
        );
      }

      if (region.type === "CHECKBOX") {
        return (
          <div className="absolute flex" key={i} style={{ ...style }}>
            <input className="w-full h-full outline-none" type="checkbox" onChange={e => updateTextValue(region.id, e.currentTarget.checked ? "CHECKED" : "")} checked={!!state.fieldValues[region.id]} />
            {!state.fieldValues[region.id] &&
              <div className="absolute w-full h-full pointer-events-none select-none border border-blue bg-white rounded-sm" />
            }
          </div>
        );
      }

      if (region.type === "SIGNATURE") {
        if (signatureData) {
          return <img key={i} src={signatureData} style={style} className="absolute" alt="Signature" />;
        }

        return (
          <button key={i} className="btn btn-secondary absolute border border-blue border-dashed text-blue" style={style} onClick={openModal}>
            {t("Add Signature")}
          </button>
        );
      }

      return null;
    });

  return (
    <div className="absolute top-0 left-0 w-full h-full z-10">
      {regions}
    </div>
  );
}

export default PageOverlay;