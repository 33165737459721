import { useTranslation } from "react-i18next";

function AppLanding() {
  const { t } = useTranslation();

  return (
    <div className="fixed flex w-full h-full bg-gray">
      <div className="flex flex-col items-center m-auto px-2 max-w-[800px] text-center">
        <img src="https://static.portant.co/logo-full-blue.png" className="mb-4 mx-auto" width={160} alt="Portant Logo" />
        <div className="mb-4 font-semibold font-title">
          {t("Generate contracts and request signatures easily with Portant")}
        </div>
        <a href="https://www.portant.co" className="btn btn-primary">
          {t("Learn More")}
        </a>
      </div>

    </div>
  )
}

export default AppLanding;
