import { XIcon } from "@heroicons/react/outline";
import { useContext } from "react";
import { SignatureRequestContext } from "../contexts";
import { useTranslation } from "react-i18next";


function ContinueModal(props: { visible: boolean, closeModal: () => void, onContinue: () => void }) {
  const { branding } = useContext(SignatureRequestContext);
  const { t } = useTranslation();

  if (!props.visible)
    return null;

  return (
    <div className="flex absolute top-0 left-0 w-full h-full z-50 backdrop-filter backdrop-blur-sm">
      <div className="flex flex-col relative mt-auto lg:m-auto p-8 w-full lg:w-[600px] bg-blue text-white lg:rounded shadow-lg" style={{ backgroundColor: branding?.colour }}>
        <XIcon className="w-4 h-4 absolute top-4 right-4 text-gray hover:text-white cursor-pointer" onClick={props.closeModal} />
        <div className="relative mx-auto text-lg font-semibold mb-4">
          {t("Almost there!")}
        </div>
        <div className="text-center mb-4">
          {t("I agree to be legally bound by the document and the")}<br /><a className="underline" href="https://www.portant.co/terms-and-conditions" target="_blank" rel="noreferrer">{t("Portant Terms of Service")}</a>. {t("Click on 'I Agree' to submit this document.")}
        </div>
        <div className="flex justify-between items-center mt-4">
          <button className="underline hover:cursor-pointer" onClick={props.closeModal}>
            {t("Continue Editing")}
          </button>
          <button className="btn bg-white text-blue w-40" onClick={() => { props.onContinue(); props.closeModal() }} style={{ color: branding?.colour }}>
            {t("I Agree")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ContinueModal;