import { useContext, useEffect, useState } from "react";
import { FieldRegion } from "../types";
import { DocumentContext, ModalContext } from "../contexts";
import { MailIcon, MailOpenIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";

function PreviewPageOverlay(props: { width: number, pageNumber: number }) {
  const { pageNumber } = props;

  const { t } = useTranslation();

  const { signatureRequest, signableDocument, pdfDocument } = useContext(DocumentContext);
  const { openModal, setRecipientModal } = useContext(ModalContext);

  const [scale, setScale] = useState<number>(1);

  const isPreview = signatureRequest!.recipientNumber === 0;

  const RECIPIENT_COLOURS: Record<number, string> = {
    1: '#1875FB',
    2: '#4e2fd4',
    3: '#0bb16f',
    4: '#e04141',
  }

  useEffect(() => {
    try {
      pdfDocument?.getPage(pageNumber).then((page) => {
        const viewport = page.getViewport({ scale: 1 });
        setScale(props.width / viewport.width);
      });
    } catch (e) { console.error(e) }
  }, [pdfDocument, pageNumber, props.width]);

  function getStatus(recipientNumber: number) {
    return signatureRequest?.allRecipientsMap?.[recipientNumber]?.status || "PENDING";
  }

  const regions = signableDocument!.fieldRegions
    .filter(r => (isPreview && ["PENDING", "REQUESTED", "ACCESSED"].includes(getStatus(r.recipient))) || r.recipient === signatureRequest!.recipientNumber)
    .filter(r => r.pageNumber === pageNumber)
    .map((region: FieldRegion, i: number) => {
      const style = {
        left: region.bbox.x0 * scale - 2,
        top: region.bbox.y0 * scale - 2,
        width: (region.bbox.x1 - region.bbox.x0) * scale + 2 + (region.type === "CHECKBOX" ? 2 : 0),
        height: (region.bbox.y1 - region.bbox.y0) * scale + 2 + (region.type === "CHECKBOX" ? 2 : 0),
        fontSize: 12 * scale,
        color: RECIPIENT_COLOURS[region.recipient] + (region.type === "SIGNATURE" ? "ff" : "80"),
        backgroundColor: RECIPIENT_COLOURS[region.recipient] + "0a", // opacity
        borderColor: RECIPIENT_COLOURS[region.recipient],
      }

      if (!signatureRequest?.allRecipientsMap?.[region.recipient]?.email)
        return null;

      if (region.type === "DATE") {
        return (
          <div key={i} className="absolute flex items-center justify-center whitespace-nowrap border border-dashed select-none px-2" style={style}>
            __/__/____
          </div>
        );
      }

      if (region.type === "TEXT") {
        return (
          <div key={i} className="absolute border border-dashed rounded flex items-center font-sans italic px-2" style={{ ...style, maxWidth: (600 * scale) - (style.left + 20), minWidth: style.width, width: "auto" }}>
          </div>
        );
      }

      if (region.type === "CHECKBOX") {
        return (
          <div className="absolute flex border border-dashed rounded" key={i} style={{ ...style }}>
            <div className="absolute w-full h-full pointer-events-none select-none bg-white rounded-sm" />
          </div>
        );
      }

      if (region.type === "SIGNATURE") {
        if (getStatus(region.recipient) === "PENDING")
          return (
            <button key={i} className="btn btn-secondary absolute border flex px-4 gap-4 justify-start" style={{ ...style, minWidth: style.width, width: "auto" }} onClick={() => { openModal(); setRecipientModal(region.recipient); }}>
              <img src={`https://static.portant.co/request-signature-link-icon-u${region.recipient}.svg`} className="w-10 h-10" alt="Signature icon" />
              <div className="flex flex-col items-start">
                <div>{t("Request to Sign")}</div>
                <div className="text-sm font-normal">
                  {signatureRequest?.allRecipientsMap?.[region.recipient]?.email}
                </div>
              </div>
            </button>
          );

        if (getStatus(region.recipient) === "REQUESTED")
          return (
            <button key={i} className="btn btn-secondary absolute border border-dashed flex px-4 gap-4 justify-start cursor-default" style={{ ...style, minWidth: style.width, width: "auto" }}>
              <MailIcon className="w-10 h-10" />
              <div className="flex flex-col items-start">
                <div>{t("Request Sent")}</div>
                <div className="text-sm font-normal">
                  {signatureRequest?.allRecipientsMap?.[region.recipient]?.email}
                </div>
              </div>
            </button>
          );

        if (getStatus(region.recipient) === "ACCESSED")
          return (
            <button key={i} className="btn btn-secondary absolute border border-dashed flex px-4 gap-4 justify-start cursor-default" style={{ ...style, minWidth: style.width, width: "auto" }}>
              <MailOpenIcon className="w-10 h-10" />
              <div className="flex flex-col items-start">
                <div>{t("Request Accessed")}</div>
                <div className="text-sm font-normal">
                  {signatureRequest?.allRecipientsMap?.[region.recipient]?.email}
                </div>
              </div>
            </button>
          );

      }

      return null;
    });

  return (
    <div className="absolute top-0 left-0 w-full h-full z-10">
      {regions}
    </div>
  );
}

export default PreviewPageOverlay;