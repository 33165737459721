import { useTranslation } from "react-i18next";
import { luminance } from "../utils";
import { Dispatch, useContext } from "react";
import { GlobalStateContext, SignatureRequestContext } from "../contexts";
import LanguagePicker from "./LanguagePicker";

type Props = {
  currentDocumentIndex: number;
  setShowSignatureModal: Dispatch<React.SetStateAction<boolean>>;
  isPreview: boolean;
  handleClickEdit: () => void;
  handleClickAgree: () => void;
}


const MainSideCard = (props: Props) => {
  const { t } = useTranslation();
  const { currentDocumentIndex, setShowSignatureModal, isPreview, handleClickEdit, handleClickAgree } = props;
  const { state: globalState } = useContext(GlobalStateContext);
  const signatureRequest = useContext(SignatureRequestContext);
  const signableDocument = signatureRequest?.signableDocuments[currentDocumentIndex] ?? null;

  return (
    <div className="fixed bottom-0 left-0 w-full lg:static flex flex-col z-10 lg:max-w-[356px] p-6 lg:p-8 lg:rounded-lg h-min bg-blue text-white shadow-md" style={{ backgroundColor: signatureRequest.branding?.colour, color: luminance(signatureRequest.branding?.colour ?? "#000000") >= 0.5 ? "black" : "white" }}>
      {(signatureRequest.branding?.logo && globalState.status === "INITIAL") &&
        <div className="left-10 absolute top-5 hidden lg:block">
          <img src={signatureRequest.branding?.logo} className="h-12" alt="Brand Logo" />
        </div>
      }
      <div className="flex flex-col items-center justify-between w-full max-w-xl mx-auto">
        {globalState.status === "INITIAL" && <>
          <h2 className="text-left w-full font-semibold font-title text-lg lg:text-xl leading-6 lg:leading-8">{signableDocument.documentName}</h2>

          {signatureRequest.recipientNumber >= 1 &&
            <button className="btn btn-secondary mt-5 mb-4 lg:mt-6 py-3 lg:py-2.5 w-full rounded-full text-sm lg:text-base font-inter font-semibold" onClick={() => setShowSignatureModal(true)} disabled={isPreview}>
              {currentDocumentIndex === 0 ? t('Sign now') : "Continue"}
            </button>
          }
          <div className="flex items-center gap-2 font-inter font-semibold text-xs leading-[18px] lg:leading-5 lg:text-sm cursor-pointer flex-shrink-0" onClick={() => window.open("https://www.portant.co", "_blank")}>
            {t("Powered by")}
            {luminance(signatureRequest.branding?.colour ?? "#000000") >= 0.5
              ? <img src="https://static.portant.co/logo-full.png" className="h-5" alt="Portant Logo" />
              : <img src="https://static.portant.co/logo-full-white.png" className="h-5" alt="Portant Logo" />
            }
          </div>
        </>}
        {(globalState.status === "SIGNED") && <>
          <div className="text-sm flex-shrink mx-2">
            <p className="font-semibold mb-4 text-lg font-inter">{t("Almost there!")}</p>{t("I agree to be legally bound by the document and the")} <a className="underline" href="https://www.portant.co/terms-and-conditions" target="_blank" rel="noreferrer">{t("Portant Terms of Service")}</a>. {t("Click on 'I Agree' to submit this document.")} <button className="inline text-sm underline" onClick={handleClickEdit}>{t("Edit")}</button>
          </div>
          <div className="flex-col items-center w-full">
            <button className="btn btn-secondary mb-4 mt-6 w-full rounded-full" onClick={handleClickAgree}>
              {t("I Agree")}
            </button>
            {!signatureRequest.branding &&
              <div className="flex items-center font-inter font-semibold justify-center lg:text-sm leading-[18px] lg:leading-5 text-white gap-1 text-xs cursor-pointer" onClick={() => window.open("https://www.portant.co", "_blank")}>
                {t("Powered by")}
                <img src="https://static.portant.co/logo-full-white.png" width={80} alt="Portant Logo" />
              </div>
            }

          </div>
        </>}
        {globalState.status === "SUBMITTING" && <>
          <div className="mx-auto">
            <span className="font-semibold">{t("Submitting your document...")}</span>
          </div>
        </>}

        {(globalState.status !== "SUBMITTING") && <LanguagePicker className="mt-4" />}
      </div>
    </div>
  );
}

export default MainSideCard;